import React from 'react';
import PropTypes from 'prop-types';
import { linkMap } from '../data/page-map';
import { NavItem, NavLink } from "reactstrap";
import trimEnd from 'lodash.trimend';

const SelectLanguage = (props) => {
  const languageMap = {
    'en': 'English',
    'fr': 'Français'
  }

  return props.langs.filter(x => x.langKey !== props.selectedLangKey).map(lang =>
    <NavItem key={lang.langKey}>
      <NavLink href={linkMap[trimEnd(lang.link, '/')] || lang.link} data-title={languageMap[lang.langKey]}>
        {languageMap[lang.langKey]}
      </NavLink>
    </NavItem>
  )[0];
};

SelectLanguage.propTypes = {
  langs: PropTypes.array,
  selectedLangKey: PropTypes.string.isRequired
};

export default SelectLanguage;
