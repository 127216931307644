module.exports = {
  'selectLanguage': 'Select your language',
  'pageHome': 'Home',
  'pageAbout': 'About',
  'pageGigs': 'Gigs',
  'pagePressKit': 'Press Kit',
  'pageMusic': 'Music',
  'pageContact': 'Contact',
  'newsHeadline': 'Montreal CD Release of Beaton-Plasse album!',
  'newsText': 'It is with great pleasure that we invite you to join us for the Montreal release of our first album! It takes place for 6pm-8p, just before the jam, at Vices & Versa where we first met and played tunes together! Admission is FREE! Hope to see you there!<br><a href=\'https://www.facebook.com/events/2378492418857788/\'>Facebook event</a>',
  'eventDetails': 'Details',
  'eventDate': 'Date',
  'eventPlace': 'Place',
  'homeUpcomingGigs': 'Upcoming gigs',
  'homeNews': 'News',
  'aboutText': `
    <p>Two accomplished and energetic fiddlers combine forces in this new duo, <strong>Beaton-Plasse</strong>. Andrea Beaton and Véronique Plasse fuse music from Québec and Cape Breton, adding original compositions to the mix.</p>
    <p>These strong female leads take turns exploring melody and accompaniment, mesmerizing audiences with their intensity and precision. Their love for their cultures, authenticity and the delight they get from playing music together shines through every time their bows cross the strings.</p>
    <p><strong>Andrea</strong> is known as a powerhouse, and comes from two of Cape Breton's most prolific musical families. Both the Beatons and  MacMasters have been intrinsic parts of the preservation and growth of the fiddle and dance culture of the island. Andrea continues this by sharing the old Scottish melodies and step dancing, as well as contributing her own compositions to Cape Breton's repertoire. She is in high demand as a performer and teacher of her culture throughout North America and Europe.</p>
    <p><strong>Véronique</strong> is recognized as a gifted performer, arranger and teacher of the music she loves. Said to be a tour-de-force by those who know her, she plays and sings with soul and power that comes right from her inner core. She works to preserve and share the traditional music of Québec by leading jam sessions and a chorale of traditional singers who perform regularly. Her dynamism paired with her superb leadership skills have put her in high demand for performances and fiddle camps near and far.</p>
  `,
  'pressKitCoverText': 'Beaton-Plasse Album Cover',
  'pressKitOfficialPhotoText': 'Beaton-Plasse Official Press Photo',
  'contactSubscribeTitle': 'Subscribe to our mailing list',
  'contactEmail': 'Email Address',
  'contactBooking': 'Booking',
  'contactSubscribe': 'Subscribe',
  'contactForFrench': 'For French',
  'contactForEnglish': 'For English',
  'date': '{date, date, medium}',
  'musicDownload': 'Where to download and listen to samples',
  'musicBuyPhysicalDetails': 'CD {price, number, CAD} + Shipping {shipping, number, CAD}',
  'musicBuyPhysical': 'Buy physical album',
  'musicCanadaShipping': 'Canada shipping',
  'musicUnitedStatesShipping': 'USA shipping',
  'musicInternationalShipping': 'International shipping',
  'musicBuyButtonImage': 'https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif',
  'musicBuyButtonAlternativeText': 'PayPal - The safer, easier way to pay online!'
};
