const pageMap = {
  'about': {
    'en': 'about',
    'fr': 'bio'
  },
  'gigs': {
    'en': 'gigs',
    'fr': 'spectacles'
  },
  'press-kit': {
    'en': 'press-kit',
    'fr': 'dossier-de-presse'
  },
  'music': {
    'en': 'music',
    'fr': 'musique'
  },
  'contact': {
    'en': 'contact',
    'fr': 'contact'
  }
};

module.exports.pageMap = pageMap;
const linkMap = Object.keys(pageMap).reduce((aggregate, page) => {
  const languages = Object.keys(pageMap[page]);
  const languageMap = {};
  for (let i = 0; i < languages.length; i++) {
    for (let j = 0; j < languages.length; j++) {
      if (i !== j) {
        languageMap[languages[i]] = languages[j];
      }
    }
  }

  Object.keys(pageMap[page]).forEach(lang => {
    aggregate[`/${lang}/${pageMap[page][languageMap[lang]]}`] = `/${lang}/${pageMap[page][lang]}`;
  });

  return aggregate;
}, {});

module.exports.linkMap = linkMap;
