import React from 'react'
import styles from './footer.module.css';
import { IoLogoFacebook, IoLogoYoutube } from 'react-icons/io'

const Footer = (props) => {
  return (
    <footer className={styles.container}>
      <div>
        Beaton-Plasse
      </div>
      <div>
        <a href="https://www.facebook.com/Beaton-Plasse-2233326000228528/">
          <IoLogoFacebook /> facebook.com
        </a>
      </div>
      <div>
        <a href="https://www.youtube.com/channel/UC4s3fiJAo9yiIKzrDrXbbJQ">
          <IoLogoYoutube /> YouTube.com
        </a>
      </div>
    </footer>
  );
};

export default Footer;
