import React from 'react'
import PropTypes from 'prop-types';
import Link from 'gatsby-link'
import styles from './logo.module.css';

const Logo = (props) => {
  const selectedLangKey = props.langs.find(lang => lang.selected).langKey;
  return (
    <div className={styles.container}>
        <div className={styles.logo}>
          <Link to={`/${selectedLangKey}`}>
            <img src="/images/logo.svg" alt="Beaton-Plasse" />
          </Link>
        </div>
    </div>
  );
};

Logo.propTypes = {
  langs: PropTypes.array
};

export default Logo;
