// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-page-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-pages-404-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-gigs-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/en/gigs.js" /* webpackChunkName: "component---src-pages-en-gigs-js" */),
  "component---src-pages-en-index-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-music-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/en/music.js" /* webpackChunkName: "component---src-pages-en-music-js" */),
  "component---src-pages-en-press-kit-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/en/press-kit.js" /* webpackChunkName: "component---src-pages-en-press-kit-js" */),
  "component---src-pages-fr-bio-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/fr/bio.js" /* webpackChunkName: "component---src-pages-fr-bio-js" */),
  "component---src-pages-fr-contact-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/fr/contact.js" /* webpackChunkName: "component---src-pages-fr-contact-js" */),
  "component---src-pages-fr-dossier-de-presse-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/fr/dossier-de-presse.js" /* webpackChunkName: "component---src-pages-fr-dossier-de-presse-js" */),
  "component---src-pages-fr-index-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-musique-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/fr/musique.js" /* webpackChunkName: "component---src-pages-fr-musique-js" */),
  "component---src-pages-fr-spectacles-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/fr/spectacles.js" /* webpackChunkName: "component---src-pages-fr-spectacles-js" */),
  "component---src-pages-index-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-photos-js": () => import("/home/travis/build/hanstdam/beaton-plasse.com/src/pages/press-photos.js" /* webpackChunkName: "component---src-pages-press-photos-js" */)
}

exports.data = () => import("/home/travis/build/hanstdam/beaton-plasse.com/.cache/data.json")

