import React from 'react'
import SelectLanguage from './select-language';
import intl from 'react-intl-universal';
import { pageMap } from '../data/page-map';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import styles from './header.module.css';
import trimEnd from 'lodash.trimend';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.selectedLangKey = props.currentLanguage;
    this.langs = props.langs;

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

    this.url = props.url;
    this.intl = props.intl;
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const links = [
      {
        id: null,
        nameKey: 'pageHome'
      },
      {
        id: 'about',
        nameKey: 'pageAbout'
      },
      {
        id: 'gigs',
        nameKey: 'pageGigs'
      },
      {
        id: 'press-kit',
        nameKey: 'pagePressKit'
      },
      {
        id: 'music',
        nameKey: 'pageMusic'
      },
      {
        id: 'contact',
        nameKey: 'pageContact'
      }
    ].map(page => {
      let href = `/${this.selectedLangKey}`;
      if (page.id) {
        href += `/${pageMap[page.id][this.selectedLangKey]}`;
      }

      const isActive = trimEnd(this.url.toLowerCase(), '/') === trimEnd(href.toLowerCase(), '/');

      const getKey = (id) => intl.get(id)
      const itemCaption = getKey(page.nameKey);

      return (
        <NavItem key={page.nameKey} active={isActive}>
          <NavLink href={href} data-title={itemCaption}>
            {itemCaption}
          </NavLink>
        </NavItem>
      );
    });

    return (
      <div>
        <Navbar expand="md" dark={true} className={(this.state.isOpen ? "navbar-dark-bg" : "")}>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar className={styles.collapse}>
            <Nav navbar>
              {links}
              <SelectLanguage langs={this.langs} selectedLangKey={this.selectedLangKey} />
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Header;
