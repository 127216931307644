import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header'
import Logo from '../components/logo'
import Footer from '../components/footer'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import trimEnd from 'lodash.trimend';
import 'intl';
import intl from 'react-intl-universal';
import IntlPolyfill from "intl";
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/en';
import en from '../data/translations/en';
import fr from '../data/translations/fr';
import languages from '../data/languages';
import { Location } from '@reach/router';

// For Node.js, common locales should be added in the application
global.Intl = IntlPolyfill;

const Layout = ({ children }) => {
  return (
    <Location>
      {({ location }) => {
        const url = location.pathname;
        const { langs, defaultLangKey } = languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}/`;
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));

        intl.init({
          currentLocale: langKey,
          locales: {
            [langKey]: {
              'en': en,
              'fr': fr
            }[langKey]
          }
        });

        const contentClasses = [
          "content"
        ];

        if (!trimEnd(url.toLowerCase(), '/').length || trimEnd(url.toLowerCase(), '/') === trimEnd(homeLink.toLowerCase(), '/')) {
          contentClasses.push("home");
        } else {
          contentClasses.push("regular");
        }

        return (
          <div className="wrapper" data-url={url} data-lang-key={langKey} data-menu={JSON.stringify(langsMenu)}>
            <div className={contentClasses.join(' ')}>
              <Helmet
                title="Beaton-Plasse"
                meta={[
                  { name: 'description', content: '' },
                  { name: 'keywords', content: 'celtic, quebec, cape breton, fiddle, duo, beaton, plasse' },
                ]}
              />
              <div className="hero">
                <Logo langs={langsMenu} />
                <Header langs={langsMenu} currentLanguage={langKey} url={url} />
              </div>
              {children}
            </div>
            <Footer />
          </div>
        );
      }}
    </Location>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired
}

export default Layout;
