module.exports = {
  'selectLanguage': 'Choisissez votre langue',
  'pageHome': 'Accueil',
  'pageAbout': 'Bio',
  'pageGigs': 'Spectacles',
  'pagePressKit': 'Dossier de presse',
  'pageMusic': 'Musique',
  'pageContact': 'Contact',
  'newsHeadline': 'Lancement Montréal 6@8 // Album Beaton-Plasse',
  'newsText': 'C\'est avec un grand plaisir que nous vous convions au lancement Montréalais du premier album de Beaton-Plasse. En formule 6@8 au Vice&Versa, juste avant le jam où nous avons croisé l\'archet pour la première fois.<br>Entrée libre<br><a href=\'https://www.facebook.com/events/2378492418857788/\'>Événement Facebook</a>',
  'eventDetails': 'Détails',
  'eventDate': 'Date',
  'eventPlace': 'Endroit',
  'homeUpcomingGigs': 'Prochains spectacles',
  'homeNews': 'Nouvelles',
  'aboutText': `
    <p>Deux violonistes énergiques et accomplies combinent leur force dans ce nouveau duo de musique traditionnelle, <strong>Beaton-Plasse</strong>. Andrea et Véronique fusionnent la musique du Québec et du Cap-Breton, tout en incluant des compositions originales à ce riche mélange.</p>
    <p>À tour de rôle, elles explorent les mélodies et les accompagnements des suites de pièces qu’elles proposent, captivant leur public de leur intensité et de leur précision. L’amour pour leurs cultures, leur authenticité et le plaisir qu’elles prennent à jouer ensemble rayonnent chaque fois que leurs archets se croisent.</p>
    <p><strong>Andrea Beaton</strong> est reconnue pour son énergie rassembleuse et elle vient des plus prolifiques familles de musiciens du Cap-Breton. Les Beaton et les MacMasters sont une part considérable de la préservation et de la transmission de la culture de musique et de danse traditionnelle de l’île. Andrea est un maillon de la chaine, partageant les vieilles mélodies et gigues écossaises, tout en enrichissant le répertoire du Cap-Breton de ses propres compositions. Elle est en grande demande en tant qu’interprète et enseignante de sa culture à travers l’Amérique du Nord et l’Europe.</p>
    <p><strong>Véronique Plasse</strong> est une artiste généreuse qui porte, crée, arrange et enseigne cette musique traditionnelle du Québec qui la fait vibrer. Reconnue pour être une force vive par ceux qui la côtoient, elle joue et chante avec âme et passion qui lui viennent droit du cœur. Elle œuvre à préserver et transmettre sa musique traditionnelle par plusieurs moyens (sessions, chorale, Trad’orchestre, etc.).  Son dynamise combiné à son grand leadership lui valent sa prolifique demande à titre d’interprète et d’enseignante, tant ici qu’à l’international.</p>
  `,
  'pressKitCoverText': 'Beaton-Plasse Couverture de l\'album',
  'pressKitOfficialPhotoText': 'Beaton-Plasse Photo de presse officielle',
  'contactSubscribeTitle': 'Abonnez-vous à notre liste de diffusion',
  'contactEmail': 'Adresse e-mail',
  'contactBooking': 'Booking',
  'contactSubscribe': 'Souscrire',
  'contactForFrench': 'Pour le français',
  'contactForEnglish': 'Pour l\'anglais',
  'date': '{date, date, medium}',
  'musicDownload': 'Où télécharger et écouter des échantillons',
  'musicBuyPhysicalDetails': 'Album {price, number, CAD} + Livraison {shipping, number, CAD}',
  'musicBuyPhysical': 'Acheter un album physique',
  'musicCanadaShipping': 'Expédition au Canada',
  'musicUnitedStatesShipping': 'USA expédition',
  'musicInternationalShipping': 'Expédition internationale',
  'musicBuyButtonImage': 'https://www.paypalobjects.com/fr_CA/i/btn/btn_buynowCC_LG.gif',
  'musicBuyButtonAlternativeText': 'PayPal - la solution de paiement en ligne la plus simple et la plus sécurisée !'
};
